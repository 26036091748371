import React, {useState, useEffect, useMemo } from 'react';
import Footer from '../footer/';
import './home.css';
import Modal from 'react-modal';
import { Card, Option, Arrow } from '../../components/index';
import Carousel from 'react-elastic-carousel';
import { Redirect, useHistory } from 'react-router-dom';
import API from '../../api/api';

const Home = () => {
    const history = useHistory();
    const [modalIsOpen, setIsOpen] = useState(true);
    const [isLoggin, setIsLoggin] = useState(false);
    const [course, setCourse] = useState(null);
    const [profile, setProfile] = useState(null);

    const [progress, setProgress] = useState(0);
    // modal course
    const [courseOpen, setCourseOpen] = useState(false);

    useMemo(() => {
      if (window.localStorage.getItem('userData')){
        let sessionUser = JSON.parse(localStorage.getItem('userData'));
        // console.log('profile:', sessionUser);
        setProfile(sessionUser);
        setIsLoggin(true);
      } else {
        setIsLoggin(false);
      }
    }, []);

    useEffect(() => {
        // get data user-courses
        getProgress();
    }, [progress]);

    // verify login
    if (isLoggin === false) {
      return (<Redirect to={"/login"} />);
    } else {

      if (modalIsOpen){
        document.body.className="body-intern no-scroll";
      } else {
        document.body.className="body-intern";
      }
    }

    function logout() {
      if (window.localStorage.getItem('userData')){
        window.localStorage.clear();
        // redirigir home
        history.push('/login');
      }
    }

    function getProgress() {
      if (window.localStorage.getItem('userData')){
        let sessionUser = JSON.parse(localStorage.getItem('userData'));
        API.get(`/course/all_course.php?idUser=${sessionUser.iduser}`)
          .then(res => {
            if (res.data.success) {
              let pc = res.data.progress_course;
              let total = 0;

              if (pc.length > 0) {
                pc.map((item, i) => (
                  total+=  parseInt(item.progress)
                ))
              }
              console.log("avg-", total/4);
              setProgress(total/4);
            }
        });
      }
    }

    return (
      <div className="w-full home">
          <div className="flex flex-col ">
            <div className='flex flex-row-reverse bg-white py-2 align-center'>
              <label className='text-sm mx-2 btn-logout' onClick={() => logout()}>Salir</label>
              <label className='text-sm mx-2'>{profile.email}</label>
            </div>
            <div className="flex flex-row-reverse pt-8 pr-6">
              <div className="text-center align-center">
                <img className="my-0 mx-auto" src="/images/start.png" width={42} />
                <span className="text-sm text-gray-400 font-light tracking-wide">AVANCE</span>
                <p className="text-sm text-white font-bold tracking-wide -mt-1">{progress}%</p>
              </div>
              <div className="mx-s pt-2">
                <div class="shadow w-24 md:w-56 bg-gray-100 m-2 rounded-full " style={{ padding: '0.07rem'}}>
                  <div class="bg-red-600 text-xs leading-none py-1 rounded-full" style={{width: `${progress}%`, height: 4}}></div>
                </div>
              </div>
            </div>

            <div className="md:mt-12 mt-8">
              <p className="text-4xl tracking-wide md:px-24 px-10 sm:pt-4 md:pt-3 pt-2 leading-10 font-semibold w-full text-white text-center">Explora los contenidos</p>

            <div className="lg:hidden flex mt-4 md:px-8 px-2">
              <Carousel itemsToShow={1} pagination={false} renderArrow={Arrow} >
                <Option
                  title="Inteligencia Emocional"
                  onClick={() => {
                    setCourse('/media/Presupuesto_e_ingresos/story.html')
                    setCourseOpen(true);
                  }}
                  image="/images/icons/icon_01.png"
                  text="Mejora tu capacidad para entender y gestionar tus emociones."
                />
                <Option
                  title="Manejo del Tiempo"
                  onClick={() => {
                    setCourse('/media/Gastos_y_credito-web/story.html')
                    setCourseOpen(true);
                  }}
                  image="/images/icons/icon_02.png"
                  text="Aprende técnicas efectivas para administrar tu tiempo y ser más productivo."
                />

                <Option
                  title="Simulador de Entrevista Laboral"
                  onClick={() => {
                    setCourse('/media/El_mundo_Bancoppolis-web/story.html')
                    setCourseOpen(true);
                  }}
                  image="/images/icons/icon_03.png"
                  text="Prepárate para entrevistas laborales con nuestro simulador interactivo."
                />
                <Option
                  title="Redacción Corporativa"
                  onClick={() => {
                    setCourse('/media/El_detective_del_gasto/story.html')
                    setCourseOpen(true);
                  }}
                  image="/images/icons/icon_04.png"
                  text="Identifica la importancia de la comunicación escrita y aprende a redactar correos claros y efectivos"
                />
              </Carousel>
            </div>

            <div className="lg:flex hidden flex-col lg:flex-row md:mt-12 mt-8 mb-12 justify-center cursos">
            <Option
                  title="Inteligencia Emocional"
                  onClick={() => {
                    setCourse('/media/Presupuesto_e_ingresos/story.html')
                    setCourseOpen(true);
                  }}
                  image="/images/icons/icon_01.png"
                  text="Mejora tu capacidad para entender y gestionar tus emociones."
                />
                <Option
                  title="Manejo del Tiempo"
                  onClick={() => {
                    setCourse('/media/Gastos_y_credito-web/story.html')
                    setCourseOpen(true);
                  }}
                  image="/images/icons/icon_02.png"
                  text="Aprende técnicas efectivas para administrar tu tiempo y ser más productivo."
                />

                <Option
                  title="Simulador de Entrevista Laboral"
                  onClick={() => {
                    setCourse('/media/El_mundo_Bancoppolis-web/story.html')
                    setCourseOpen(true);
                  }}
                  image="/images/icons/icon_03.png"
                  text="Prepárate para entrevistas laborales con nuestro simulador interactivo."
                />
                <Option
                  title="Redacción Corporativa"
                  onClick={() => {
                    setCourse('/media/El_detective_del_gasto/story.html')
                    setCourseOpen(true);
                  }}
                  image="/images/icons/icon_04.png"
                  text="Identifica la importancia de la comunicación escrita y aprende a redactar correos claros y efectivos"
                />
              
            </div>

            <div className="md:mt-12 mt-8">
              <p className="text-4xl tracking-wide md:px-24 px-10 sm:pt-4 md:pt-3 pt-2 leading-10 font-semibold w-full text-white text-center">Conoce nuestro portafolio</p>
            </div>

            <div className="lg:hidden flex mt-4 md:px-8 px-2">
              <Carousel itemsToShow={1} pagination={false} renderArrow={Arrow} >
                <Option
                  title="Plataformas Elearning"
                  onClick={() => {
                    setCourse('https://www.youtube.com/embed/6ilxdtHCGLg?si=v_VSLAiFmv_okWpV')
                    setCourseOpen(true);
                  }}
                  image="/images/cards/portada-1.png"
                  text=""
                />
                <Option
                  title="Contenidos Virtuales"
                  onClick={() => {
                    setCourse('https://www.youtube.com/embed/CZWNE9hbn3w?si=Xm3fswddo73VF0dc')
                    setCourseOpen(true);
                  }}
                  image="/images/cards/portada-2.png"
                  text=""
                />
                <Option
                  title="Desarrollo Web"
                  onClick={() => {
                    setCourse('https://www.youtube.com/embed/8nfAOfXOS04?si=JYPvvZ0zsgddBXDg')
                    setCourseOpen(true);
                  }}
                  image="/images/cards/portada-3.png"
                  text=""
                />
                <Option
                  title="Producción de Video"
                  onClick={() => {
                    setCourse('https://www.youtube.com/embed/EZAw_3TPUS4?si=kaGp2G5pCtVmV-L7')
                    setCourseOpen(true);
                  }}
                  image="/images/cards/portada-4.png"
                  text=""
                />
                <Option
                  title="Gamificación"
                  onClick={() => {
                    setCourse('https://www.youtube.com/embed/jS2RX3i9EMM?si=BlsYPbv9VltbPVWo')
                    setCourseOpen(true);
                  }}
                  image="/images/cards/portada-5.png"
                  text=""
                />
                <Option
                  title="Capacitación y Entrenamiento"
                  onClick={() => {
                    setCourse('https://www.youtube.com/embed/NKEng4zDEko?si=-6Q4xcG6ic4hS1RV')
                    setCourseOpen(true);
                  }}
                  image="/images/cards/portada-6.png"
                  text=""
                />
                <Option
                  title="Casos de Éxito"
                  onClick={() => {
                    setCourse('https://www.youtube.com/embed/ngtrj3vjmvc?si=zt-Ij8XRngRsBH3f')
                    setCourseOpen(true);
                  }}
                  image="/images/cards/portada-7.png"
                  text=""
                />
              </Carousel>
            </div>

            <div className="lg:flex hidden flex-col lg:flex-row md:mt-12 mt-8 mb-12 justify-center cursos flex-wrap">
              <Option
                title="Plataformas Elearning"
                onClick={() => {
                  setCourse('https://www.youtube.com/embed/6ilxdtHCGLg?si=v_VSLAiFmv_okWpV')
                  setCourseOpen(true);
                }}
                image="/images/cards/portada-1.png"
                text=""
              />
              <Option
                title="Contenidos Virtuales"
                onClick={() => {
                  setCourse('https://www.youtube.com/embed/CZWNE9hbn3w?si=Xm3fswddo73VF0dc')
                  setCourseOpen(true);
                }}
                image="/images/cards/portada-2.png"
                text=""
              />
              <Option
                title="Desarrollo Web"
                onClick={() => {
                  setCourse('https://www.youtube.com/embed/8nfAOfXOS04?si=JYPvvZ0zsgddBXDg')
                  setCourseOpen(true);
                }}
                image="/images/cards/portada-3.png"
                text=""
              />
              <Option
                title="Producción de Video"
                onClick={() => {
                  setCourse('https://www.youtube.com/embed/EZAw_3TPUS4?si=kaGp2G5pCtVmV-L7')
                  setCourseOpen(true);
                }}
                image="/images/cards/portada-4.png"
                text=""
              />
              <Option
                title="Gamificación"
                onClick={() => {
                  setCourse('https://www.youtube.com/embed/jS2RX3i9EMM?si=BlsYPbv9VltbPVWo')
                  setCourseOpen(true);
                }}
                image="/images/cards/portada-5.png"
                text=""
              />
              <Option
                title="Capacitación y Entrenamiento"
                onClick={() => {
                  setCourse('https://www.youtube.com/embed/NKEng4zDEko?si=-6Q4xcG6ic4hS1RV')
                  setCourseOpen(true);
                }}
                image="/images/cards/portada-6.png"
                text=""
              />
              <Option
                title="Casos de Éxito"
                onClick={() => {
                  setCourse('https://www.youtube.com/embed/ngtrj3vjmvc?si=zt-Ij8XRngRsBH3f')
                  setCourseOpen(true);
                }}
                image="/images/cards/portada-7.png"
                text=""
              />
            </div>
            <br/><br/>

          </div>
        </div>
        
        <Footer />
      
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => {
            setIsOpen(false);
          }}
          className="modalInt"
          overlayClassName="overlay"
          contentLabel="Home"
        >
          <div className="flex flex-col">
            <div className="close flex flex-row-reverse text-right pt-3">
              <img className="cursor-pointer" alt={`icon close`} src="/images/btn_cerrar.png" style={{width: '50px'}} onClick={() => setIsOpen(false)} />
            </div>
        
            <div className="flex flex-col md:mt-20 mt-12 mb-8 md:mb-4 mb-0 md:mx-4 mx-0 md:px-8 px-4 bg-white">
              <Card title="Instrucción 01" icon="/images/inst/inst_01.png" text="En nuestra plataforma encontrarás tres cursos gratuitos y un simulador para que los disfrutes. Además, podrás conocer más sobre nuestros servicios." />
              <Card title="Instrucción 02" icon="/images/inst/inst_02.png" text="Puedes realizar los cursos y usar el simulador las veces que desees." />
              <Card title="Instrucción 03" icon="/images/inst/inst_03.png" text="Desbloquea todos los contenidos y accede a herramientas que te permitirán cumplir tus metas." />
            </div>
          </div>

        </Modal>

        <Modal
          isOpen={courseOpen}
          onRequestClose={() => {
            setCourseOpen(false)
            /* setProgress(0) */
            getProgress()
          }}
          className="modalCourse"
          overlayClassName="overlay"
          contentLabel="Home"
        >
          <div className="close">
              <img className="cursor-pointer" alt={`icon close`} src="/images/btn_cerrar.png" style={{width: '50px'}} 
                onClick={() => {
                  setCourseOpen(false);
                  getProgress();
                } 
                } />
            </div>
          <iframe  src={course} width="100%" height="100%">
          </iframe>
        </Modal>

      </div>
    )
}

export default Home;