import React from 'react';
import './option.css';

export const Option = (
{image, title, text, onClick}
) => {
  return (
    <div onClick={onClick} className="option cursor-pointer flex flex-col text-center md:px-4 px-0 py-2 m-2">
      <img className="my-0 mx-auto rounded-lg" alt={`option ${title}`} src={image} style={{ maxWidth: '220px', maxHeight: '165px'}} />
      <p className="text-xl py-2 font-extrabold tracking-wide text-white">{title}</p>
      <span className="lg:px-4 px-2 md:px-4 text-gray-400 font-light tracking-wide">
        {text}
      </span>
    </div>
  )
}