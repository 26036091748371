import React from 'react';

export const Card = (
  {icon, title, text}
) => {
  return (
    <div className="flex items-center mb-3">
      <div className="card md:mr-10 mr-4 pt-2 self-start">
        <div className="card-img" style={{backgroundImage: `url('${icon}')` }}>
        </div>
      </div>

      <div>
        <span className="block font-bold text-lg text-black">{title}</span>
        <p className="font-normal leading-tight tracking-wide text-xs">
        {text}
        </p>
      </div>
    </div>
  )

}  