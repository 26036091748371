import React, {useState, useMemo, createRef} from 'react';
import Footer from '../footer/';
import './login.css';
import Modal from 'react-modal';
import axios from 'axios';
import { Redirect, useHistory } from 'react-router-dom';

import API from '../../api/api';

const Login = () => {

  const history = useHistory();
  const [isLoggin, setIsLoggin] = useState(false);
  const [modalIsOpen,setIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  // inputs ref-login
  let one = createRef();
  let two = createRef();
  // inputs ref-register
  let rone = createRef();
  let rtwo = createRef();
  let rthree = createRef();
  let rfour = createRef();
  let rfive = createRef();
  let rsix = createRef();
  // CODE ACTIVE
  const listCode = ["CODE01CPX", "CODE02CPX", "CODE03CPX"];

  // inputs login
  const [username, setUserName] = useState('');
  const [userpass, setUserPass] = useState('');
  const [error, setError] = useState(null);
  // inputs register
  const [userjob, setUserJob] = useState('');
  const [usercompany, setUserCompany] = useState('');
  const [useremail, setUserEmail] = useState('');
  const [userphone, setUserPhone] = useState('');

  useMemo(() => {
    if (localStorage.getItem('userData')) {
      let sessionUser = JSON.parse(localStorage.getItem('userData'));
      setIsLoggin(true);
    } else {
      console.log("no data user");
      setIsLoggin(false);
    }
  }, []);

  // validate login
  function onLogin () {
    if (useremail.length > 0) {
      const dtuser = {
        "user_email": useremail
      };

      API.post('/auth/auth_user_email.php', dtuser)
      .then(res => {
        if(res.status === 200) {
          if (res.data.success) {
            localStorage.setItem('userData',JSON.stringify(res.data.user));
            // redirigir home
            history.push('/home');
          } else {
            console.log('invalid', res.data.msg);
            setError(res.data.msg);
          }
        } else {
          console.log('err status', res);
        }
      }).catch(e => {
        console.log('errAxios', e);
      });

    } else {
      setError('Datos incompletos');
    }
  }

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  // register User
  function onRegister() {
    let valid = true;
    if (username.length > 0 
        && userjob.length > 0
        && usercompany.length > 0
        && useremail.length > 0
        && userpass.length > 0) {

          // validate email
          if (useremail.length > 0) {
            if(!validateEmail(useremail)) {
              setError('Correo no válido');
              valid = false;
            }
          }

          /* if(valid) {
            // validate code-pass
            if(listCode.includes(userpass) == false) {
              setError('El código de acceso es incorrecto');
              valid = false;
            }
          } */

          if(valid) {
            setError(null);
            const dtregister = {
              "user_nickname": username,
              "user_email": useremail,
              "user_pass": userpass,
              "user_job": userjob,
              "user_company": usercompany,
              "user_phone": userphone
            };

            API.post('/user/add_user_full.php', dtregister)
            .then(res => {
              if(res.status === 200) {
                if (res.data.success) {
                  // redirigir a login
                  onLogin();
                } else {
                  setError(res.data.msg);
                }
              } else {
                console.log('err status', res);
              }
            }).catch(e => {
              console.log('errAxios', e);
            })
          }

    } else {
      setError('Datos incompletos');
    }
  }

  // clear inputs, errors, pages
  function clearData() {
    setPage(1);
    setError(null);
    setUserName('');
    setUserPass('');
    setUserJob('');
    setUserCompany('');
    setUserPhone('');
    setUserEmail('');
  }

  // When Enter is pressed, set the focused state to the next element ID provided in each input
  function handleKeyUp(e) {
    e.which = e.which || e.keyCode;

    // If the key press is Enter
    if (e.which === 13 || e.which === 9) {
      switch (e.target.id) {
        case "input-one":
          two.current.focus();
          break;
        case "input-two":
          one.current.focus();
          break;

        default:
          break;
      }
    }
  }

  function handleKeyUpReg(e) {
    e.which = e.which || e.keyCode;

    if (e.which === 13 || e.which === 9) {
      switch(e.target.id) {
        case "input-rone":
          rtwo.current.focus();
          break;
        case "input-rtwo":
          rthree.current.focus();
          break;
        case "input-rthree":
          rfour.current.focus();
          break;
        case "input-rfour":
          rfive.current.focus();
          break;
        case "input-rfive":
          rsix.current.focus();
          break;
        case "input-rsix":
          rone.current.focus();
          break;

        default:
          break;
      } 
    }
  }

  // verify login
  if (isLoggin === true) {
    console.log('session', isLoggin);
    return (<Redirect to={"/home"} />);
  } else {  
    document.body.className="";
  }

  return (
    <div className="sm:w-3/5 lg:w-2/4 w-full login">
      <div className="text-initial md:mt-48 mt-0 w-full text-center md:pt-0 pt-0">
        <span className="block sm:mt-4 sm:text-6xl text-4xl text-blue-600 text-whites font-extrabold tracking-wide">
          ¡HOLA!
        </span>
        <span className="sm:text-2xl md:px-6 sm:px-2 px-1 text-md sm:text-gray-800 text-whites tracking-wide sm:leading-normal leading-snug txt-intro">
          Bienvenid@ a nuestra plataforma de demos. <br />
          Explora nuestros contenidos virtuales diseñados con pasión y descubre cómo nuestras soluciones pueden ayudarte a alcanzar tus metas.
        </span>
      
        <div className="md:mt-3 sm:mt-8 mt-0 w-full text-center">
          <img className="block cursor-pointer" alt={`btn ingresar`} style={{ margin: '0 auto', maxWidth: '180px'}} src="images/btn_ingresar.png" onClick={() => setIsOpen(true)} />
          <img className="block cursor-pointer" alt={`btn registro`} style={{ margin: '0 auto', maxWidth: '165px'}} src="images/btn_registro.png" 
            onClick={() => {
              setPage(2)
              setIsOpen(true)}
          }  
        />
      </div>
    </div>
      
      <Footer />
      <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => {
            setIsOpen(false);
            clearData();
          }}
          className="modal"
          overlayClassName="overlay"
          contentLabel="Login"
        >
          <div className="flex flex-col">
            <div className="close flex flex-row-reverse text-right">
              <img className="cursor-pointer" alt={`icon close`} src="/images/btn_cerrar.png" style={{width: '32px', borderRadius: '10px'}} 
                onClick={() => { 
                  setIsOpen(false);
                  clearData() }
                } />
            </div>
            <div className="w-full flex flex-row md:mt-8 mt-16 md:mb-12 md:mb-10 ">
              <div className="sm:w-2/5 w-0"></div>
              {page === 1 && 
                <div className="sm:w-3/5 w-3/5 flex flex-col p-4 sm:text-center text-left ">
                  <span className="text-2xl text-white font-semibold leading-7 sm:px-12 px-0 pb-2 md:pt-0 pt-2 uppercase md:text-center text-left align-center">
                    Ingresa tu usuario
                  </span>
                  <hr className="border-hr" />
                  <span className="text-label leading-tight font-light text-sm md:px-2 px-0 pt-2 tracking-wide">
                    Si ya tienes una cuenta, ingresa tu email.
                    <br /><br />
                    Si eres nuevo, ¡crea tu usuario!
                  </span>
                </div>
                }
                
                {page === 2 && 
                  <div className="w-3/5 flex flex-col p-4 text-center ">
                    <span className="text-3xl text-white font-semibold leading-8 md:px-12 px-0 pt-4 pb-2 uppercase md:text-center text-left align-center">
                      Crea tu usuario
                    </span>
                    <hr className="border-hr" />
                    <span className="text-label leading-tight font-light text-sm md:px-2 px-0 pt-2 tracking-wide">
                      * Campos obligatorios
                    </span>
                  </div>
                }
            </div>

            {page === 1 &&
              <>
                <div className="mt-4 w-full flex justify-center ">
                  <div className="my-2 w-4/5 grid grid-cols-1 gap-4">
                    <form className="contents" name="login">
                      <input 
                        className="block bg-gray-400 tracking-wide font-normal text-center text-gray-700 p-2 text-sm rounded-full placeholder-gray-700 focus:placeholder-gray-600 focus:outline-none focus:shadow-xs" 
                        type="text" 
                        placeholder="Ingresa tu email" 
                        value={useremail}
                        onChange={(event) => {
                            setUserEmail(event.target.value);
                            setUserPass('');
                            setError(null);
                          }
                        }
                        id={"input-one"}
                        onKeyUp={handleKeyUp}
                        ref={one}
                      />
                      {/* <input 
                        className="block bg-gray-400 tracking-wide font-normal text-center text-gray-700 p-2 text-sm rounded-full placeholder-gray-700 focus:placeholder-gray-600 focus:outline-none focus:shadow-xs" 
                        type="text" 
                        placeholder="Código de acceso" 
                        value={userpass}
                        onChange={(event) => {
                          setUserPass(event.target.value);
                          setError(null);
                          }
                        }
                        id={"input-two"}
                        onKeyUp={handleKeyUp}
                        ref={two}
                      /> */}
                      <div className="form-check text-center hidden">
                          <input type="checkbox" className="form-check-input mr-1" id="recordar" />
                          <label className="text-sm text-gray-700 tracking-normal form-check-label" htmlFor="recordar">Recordarme</label>
                      </div>
                      {error != null &&
                        <span class="block text-sm text-center font-medium text-red-800 bg-red-200 border border-red-400 flex items-center p-2 rounded-md" role="alert">
                          {error}
                        </span>
                      }
                    </form>
                  </div>
                </div>

                <div className="sm:mt-2 mt-1 w-full text-center">
                  <img className="block cursor-pointer" alt={`btn entrar`} style={{ margin: '0 auto'}} src="images/btn_entrar.png" onClick={() => onLogin()} />
                </div>

                <div className="sm:mt-2 mt-1 mb-2 w-full text-center">
                  <span 
                    onClick={() => {
                      clearData();
                      setPage(2);
                    }} 
                    className="text-gray-700 text-xs tracking-wide font-normal underline cursor-pointer">QUIERO CREAR MI USUARIO</span>
                </div>
              </>
            }

            {page === 2 &&
              <>
                <div className="mt-10 w-full flex justify-center form">
                  <div className="my-2 w-5/6 grid grid-cols-1 gap-2">
                    <form className="contents">  
                      <span className="text-center text-xs font-medium">Nombre <span className='text-red-600'>*</span> </span>
                      <input
                        className="block bg-gray-400 tracking-wide font-normal text-center text-gray-700 p-2 text-sm rounded-full placeholder-gray-700 focus:placeholder-gray-600 focus:outline-none focus:shadow-xs" 
                        type="text" 
                        placeholder="Nombre completo" 
                        value={username}
                        onChange={(event) => setUserName(event.target.value)}
                        ref={rone}
                        id={"input-rone"}
                        onKeyUp={handleKeyUpReg}
                      />
                      <div className="grid grid-cols-1  sm:grid-cols-2 gap-2">
                        <div className="col-span-1 text-center">
                          <span className="text-xs font-medium">Cargo <span className='text-red-600'>*</span></span>
                          <input 
                            className="m-0 w-full block bg-gray-400 tracking-wide font-normal text-center text-gray-700 p-2 text-sm rounded-full placeholder-gray-700 focus:placeholder-gray-600 focus:outline-none focus:shadow-xs" 
                            type="text"
                            placeholder="Cargo"
                            value={userjob}
                            ref={rtwo}
                            id={"input-rtwo"}
                            onKeyUp={handleKeyUpReg}
                            onChange={(event) => setUserJob(event.target.value)}
                          />
                        </div>
                        <div className="col-span-1 text-center">
                          <span className="text-xs font-medium">Empresa <span className='text-red-600'>*</span></span>
                          <input 
                            className="m-0 w-full block bg-gray-400 tracking-wide font-normal text-center text-gray-700 p-2 text-sm rounded-full placeholder-gray-700 focus:placeholder-gray-600 focus:outline-none focus:shadow-xs" 
                            type="text"
                            placeholder="Empresa"
                            value={usercompany}
                            ref={rthree}
                            id={"input-rthree"}
                            onKeyUp={handleKeyUpReg}
                            onChange={(event) => setUserCompany(event.target.value)}
                          />
                        </div>
                      </div>
                      <span className="text-center text-xs font-medium">Teléfono de contacto</span>
                      <input 
                        className="block bg-gray-400 tracking-wide font-normal text-center text-gray-700 p-2 text-sm rounded-full placeholder-gray-700 focus:placeholder-gray-600 focus:outline-none focus:shadow-xs" 
                        type="tel" 
                        placeholder="Teléfono" 
                        value={userphone}
                        ref={rfour}
                        id={"input-rfour"}
                        onKeyUp={handleKeyUpReg}
                        onChange={(event) => setUserPhone(event.target.value)}
                      />
                      <span className="text-center text-xs font-medium ">Correo electrónico <span className='text-red-600'>*</span></span>
                      <input 
                        className="block bg-gray-400 tracking-wide font-normal text-center text-gray-700 p-2 text-sm rounded-full placeholder-gray-700 focus:placeholder-gray-600 focus:outline-none focus:shadow-xs" 
                        type="text" 
                        placeholder="Correo electrónico"
                        value={useremail}
                        ref={rfive}
                        id={"input-rfive"}
                        onKeyUp={handleKeyUpReg}
                        onChange={(event) => setUserEmail(event.target.value)}
                      />
                      <span className="text-center text-xs font-medium">Código de acceso <span className='text-red-600'>*</span></span>
                      <input 
                        className="block bg-gray-400 tracking-wide font-normal text-center text-gray-700 p-2 text-sm rounded-full placeholder-gray-700 focus:placeholder-gray-600 focus:outline-none focus:shadow-xs" 
                        type="text" 
                        placeholder="Código de activación" 
                        value={userpass}
                        ref={rsix}
                        id={"input-rsix"}
                        onKeyUp={handleKeyUpReg}
                        onChange={(event) => setUserPass(event.target.value)}
                      />
                      {error != null &&
                        <span className="block text-sm text-center font-medium text-red-800 bg-red-200 border border-red-400 flex items-center p-2 rounded-md" role="alert">
                          {error}
                        </span>
                      }
                    </form>
                  </div>
                </div>

                <div className="sm:-mt-2 mt-0 w-full text-center">
                    <img className="block cursor-pointer" alt={`btn crear`} style={{ margin: '0 auto'}} src="images/btn_crear.png" onClick={() => onRegister()} />
                </div>
              </>
            }
            
          </div>

        </Modal>
    </div>
    
  )
}

export default Login;