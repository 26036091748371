import React from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
// PATH COMPONENT
import Login from './pages/login/';
import Home from './pages/home/';

function App() {
  return (
    <Router>
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/login" component={Login} />
            <Route path="/home" component={Home} />
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
      </Router>
  );
}


function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export default App;
