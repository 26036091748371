import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <div className="footer ">
      <div className="footer-sm flex flex-row justify-center md:justify-between px-4">
        <>
          <span className="text-xs self-center text-gray-300 invisible hidden md:block md:visible">Copyright © 2024 Ciomprix
            {' - '}
            Todos los derechos reservados 
          </span>
        </>
        <a className="text-xs text-blue-300 hover:text-line" target="_blank" href="https://www.ciomprix.com/">
          <img src="/images/LogoBancoppel.png" alt={`logo Ciomprix`} />
        </a>
      </div>
    </div>
  )
}

export default Footer;